<template>
    <ul class="sitemap-stores-index">
        <template v-for="letter in letters.keys">
            <li
                v-if="letters.lettersData[letter] && letters.lettersData[letter].length"
                :key="letter"
                class="sitemap-stores-index__letter-sec"
            >
                <h3 class="sitemap-stores-index__title">{{ letter }}</h3>
                <ul class="sitemap-stores-index__letter-list">
                    <li v-for="(store, index) in letters.lettersData[letter]" :key="index">
                        <NuxtLink :to="'/' + $lang.routes.brands + '/' + store.slug">{{
                            store.name
                        }}</NuxtLink>
                    </li>
                </ul>
            </li>
        </template>
    </ul>
</template>

<script lang="ts" setup>
type SitemapStore = {
    name: string
    slug: string
}

const { buildHeaders, baseURL, endpoints } = useApiConfig()

const { data: responseData, error } = await useAsyncData('stores-sitemap-data', () => {
    return $fetch<SitemapStore[]>(endpoints.pages.siteMap.stores, {
        headers: buildHeaders(),
        method: 'GET',
        baseURL,
    }).catch((e) => e.data || false)
})

if (error.value || !responseData.value || responseData.value.error) {
    throw createError({
        statusCode: responseData.value?.feedback === 'resource_not_found' ? 404 : 500,
        message:
            responseData.value?.feedback === 'resource_not_found' ? 'Página no encontrada' : 'Algo salió mal',
    })
}

const sitemap = ref(responseData.value) as Ref<SitemapStore[]>

const letters = computed(() => {
    const lettersArray: { [key: string]: SitemapStore[] } = {
        '0-9': [],
        a: [],
        b: [],
        c: [],
        d: [],
        e: [],
        f: [],
        g: [],
        h: [],
        i: [],
        j: [],
        k: [],
        l: [],
        m: [],
        n: [],
        ñ: [],
        o: [],
        p: [],
        q: [],
        r: [],
        s: [],
        t: [],
        u: [],
        v: [],
        w: [],
        x: [],
        y: [],
        z: [],
    }

    const latinCharacters = 'ãàáäâèéëêìíïîòóöôùúüûÇç' /* Ññ */
    const asciiCharacters = 'aaaaaeeeeiiiioooouuuucc' /* nn */

    sitemap.value?.forEach((shop: SitemapStore) => {
        const letter = shop.name.trim().charAt(0).toLowerCase() as string

        if (!lettersArray[letter]) {
            if (isNaN(Number(letter))) {
                const indexOfLatinChar = latinCharacters.indexOf(letter)
                lettersArray[asciiCharacters.charAt(indexOfLatinChar)].push(shop)
            } else {
                lettersArray['0-9'].push(shop)
            }
        } else {
            lettersArray[letter].push(shop)
        }
    })

    return {
        keys: Object.keys(lettersArray),
        lettersData: lettersArray,
    }
})
</script>

<style lang="postcss">
.sitemap-stores-index {
    a {
        @apply lg:hover:underline;
    }
    &__letter-sec {
        @apply py-4 sm:py-6 lg:py-8;
        &:not(:last-of-type) {
            @apply border-b border-gray-200;
        }
    }
    &__title {
        @apply block text-lg font-medium capitalize;
    }
    &__letter-list {
        @apply mt-6 grid gap-5 leading-none xs:grid-cols-2 sm:grid-cols-3 lg:grid-cols-4;
    }
}
</style>
